<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Grupos de Estabelecimentos</b>

        Dialog.dialogApagar(header='Remover Empresa' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o subgrupo <b>{{ dialogApagar_data.ds_subgrupo }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        DialogSalvar(
            :visible="dialogSalvar.visible"
            :grupo="dialogSalvar.model"
            @close="onCloseDialogSalvar()"
            @saved="applyFilters(1, true)")

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-6
                    label.form-label Grupo de estabelecimento:
                    .p-inputgroup
                        InputText(placeholder='Grupo de estabelecimento'
                            @keyup.enter.native='() => !waiting && applyFilters()'
                            @input='(val) => !val && applyFilters()'
                            v-model='filters.ds_subgrupo'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' :disabled='waiting')
                .p-col-12.p-md-6
                    label.form-label Status:
                        Dropdown(
                            v-model='filters.ie_status'
                            :options='filtersOptions.ieStatus'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            placeholder='TODOS'
                            @change='applyFilters()'
                        )
        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSalvar.visible = true")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
                            .ta-left
                                p <b>Subgrupo:</b> {{ props.data.ds_subgrupo }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Grupos de Estabelecimentos')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list")
                    Column(headerStyle='width: 5%' bodyStyle='text-align:center' header='Status')
                        template(#body='props')
                            ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.ie_valor || waitingSelecionados.find(a=> a === props.data.id)' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.ie_valor)')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 4%;' field='ie_valor' header='Código')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ie_valor" style='cursor: pointer;') {{ props.data.ie_valor }}

                    //- Column(headerStyle='width: 15%;' field='procedimento.cd_procedimento' header='Código do Procedimento')
                    //-     template(#body='props')
                    //-         span(v-tooltip.top="props.data.id" style='cursor: pointer;') {{ props.data.procedimento.cd_procedimento }}
                    //- //- Column(headerStyle='width: 30%;' field='procedimento.cd_procedimento' header='Procedimento')
                    //-     template(#body='props')
                    //-         span(v-tooltip.top="props.data.procedimento.nm_procedimento" style='cursor: pointer;') {{ props.data.procedimento.nm_procedimento }}

                    Column(headerStyle='width: 15%;' field='ds_valor' header='Subgrupo')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ds_valor" style='cursor: pointer;') {{ props.data.ds_valor }}
                                        
                    Column(headerStyle='width: 8%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="onEditClick(props.data)"
                                )
                                //- Button.p-button-raised.p-button-rounded.p-button-danger(
                                //-     v-tooltip.top="'Apagar'"
                                //-     icon="jam jam-minus-circle"
                                //-     @click="dialogApagar = true; dialogApagar_data = props.data"
                                //- )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
        .status-indicator {
			display: inline-block;
			font-size: 0;
			width: 24px;
			height: 24px;
			line-height: 36px;
			border-radius: 50%;
			background-color: #94c860;
			border: 1px solid #84b553;
			transition: 250ms;
			cursor: pointer;
			&:hover { background-color: #84b553; }
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
				&:hover { background-color: #b33030; }
			}
			.jam {
				color: #fff;
			}
		}
        .waitingStatus, .waitingObservacoes {
			width: 29px;
			height: auto;
		}
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProcedimentoDropdown from "@/components/Procedimentos/ProcedimentoDropdown";
    import ProgressSpinner from 'primevue/progressspinner'
    import { DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import axios from "axios"
    import Dropdown from 'primevue/dropdown'
    import DialogSalvar from "@/components/GrupoEstabelecimentos/DialogSalvar"

    const _ = require('lodash')

    export default {
        created () { this.applyFilters() },
        components: {
            DialogSalvar, ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, ProcedimentoDropdown,
        Dropdown },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus: 0,
                waitingSelecionados: [],
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                cancelToken: null,
                params: {},
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 300, { leading: true }),
                filters: {
                    ds_valor: '',
                    ie_status: 'true',
                },
                filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                dialogSalvar: {
                    model: {
                        id: null,
                        cd_dominio_mestre: null,
                        ie_valor: '',
                        ds_valor: '',
                        ie_status: null
                    },
                    visible: false
                }
            }
        },
        methods: {
            onEditClick(model) {
                this.dialogSalvar.model = Object.assign({}, model)
                this.dialogSalvar.visible = true
            },
            onCloseDialogSalvar() {
                this.dialogSalvar.visible = false
                this.dialogSalvar.model = {}
            },
            checkEmpty_nm_fantasia () {
                if (! this.filters.nm_fantasia) this.applyFilters()
            },
            getList (params) {
                if (this.cancelToken) this.cancelToken.cancel()
                this.cancelToken = axios.CancelToken.source()

                this.waiting = true
                return DominioValor.find('GRUPO_ESTABELECIMENTOS', params).then(response => {
                    if (!response) return
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                    }
                    this.waiting = false
                    this.cancelToken = null
                })
            },
            applyFilters(page, force) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })

                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params) && !force) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 300)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            alterarStatus (ie_valor) {
				let subgrupo = this.list.find(a => a.ie_valor === ie_valor);
				this.waitingStatus = ie_valor
				DominioValor.alterarStatus(ie_valor, 'GRUPO_ESTABELECIMENTOS', {ds_valor: subgrupo.ds_valor, ie_valor: subgrupo.ie_valor, ie_status: ! subgrupo.ie_status }).then(response => {
					this.waitingStatus = 0
					if (([200,201]).includes(response.status)) {
						subgrupo.ie_status = ! subgrupo.ie_status
						if (subgrupo.ie_status)
							this.$toast.success('Subgrupo habilitado', { duration: 3000 })
						else
							this.$toast.info('Subgrupo desabilitado', { duration: 3000 })
					} else if (response.status === 400) {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
            remove () {
                this.waitingApagar = true
                DominioValor.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Subgrupo removido com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters(1, true)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            }
        }
    }
</script>

<template>
    <Dialog
        class="adicionar-area-atuacao"
        :header="`${ model.id ? 'Editar' : 'Adicionar' } subgrupo`"
        :visible.sync='show' :modal='true'
        :contentStyle="{overflowY: 'auto !important'}"
    >
        <div class="p-grid m-content">
            <div class="p-col-12 p-grid p-fluid m-inputs" :class="{ 'form-group--error': submitted && $v.model.ds_valor.$error }">
                 <div class="p-col-12">
                    <label class="form-label">Descrição:</label>
                    <InputText v-model="$v.model.ds_valor.$model" />
                    <div class="feedback--errors" v-if='submitted && $v.model.ds_valor.$error'>
                        <div class="form-message--error" v-if="!$v.model.ds_valor.minLength">
                            Deve ter pelo menos 2 caracteres.
                        </div>
                        <div class="form-message--error" v-if="!$v.model.ds_valor.required">
                            Campo obrigatório.
                        </div>
                    </div>
                 </div>       
            </div>

            
        </div>
        <template #footer>
            <div class="p-col-12 ta-right m-buttons">
                <ProgressSpinner class="waiting-add" v-if="waiting" strokeWidth="6" />
                <Button v-else label="Salvar" icon="jam jam-check" @click="save()" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import {minLength, required} from "vuelidate/lib/validators";
import {DominioValor} from "@/middleware";

export default {
    components: { Button, Dialog, InputText, ProgressSpinner },
    props: ['visible', 'grupo'],
    computed: {
        show: {
            get() { return this.visible },
            set(value) { if (!value) this.$emit('close') }
        }
    },
    // beforeUpdate() {
    //     console.log("SUBGRUPO: ", this.subgroup)
    //     if(this.$parent.dialogSalvar.model.procedimento) {
    //         this.model = {
    //             cd_procedimento: this.$parent.dialogSalvar.model.procedimento.cd_procedimento,
    //             ds_subgrupo: this.$parent.dialogSalvar.model.ds_subgrupo
    //     }
    //     }
       
    // },
    data() {
        return {
            submitted: false,
            waiting: false,
            options: {
                procedimentos: []
            },
            model: {}
        }
    },
    validations () {
        let v = {
            model: {
                ds_valor: { required, minLength: minLength(2) },
            }
        }
        return v
    },
    watch: {
        "grupo": function(val) {
            this.model = val
        }
    },
    methods: {
        save() {
            this.submitted = true;
            this.$v.$touch()
            if (this.$v.$invalid) return
            console.log(this.model)
            let dataSend = {
                ds_valor: this.model.ds_valor,
                ie_valor: this.model.ie_valor ? this.model.ie_valor : '.',
                ie_status: this.model.ie_status
                
            };
            if(this.model.id)
                dataSend['id'] = this.model.id
            let fun = dataSend.id ? 'patch' : 'save'
            this.waiting = true
            DominioValor[fun](dataSend.ie_valor, 'GRUPO_ESTABELECIMENTOS', dataSend).then(response => {
                this.waiting = false
                if ([200, 201].includes(response.status)) {
                    this.$toast.success('Salvo com sucesso.', { duration: 3000 });
                    this.$emit('saved');
                    this.submitted = false;
                    this.show = false;
                } else if ([400, 404].includes(response.status)) {
                    if (response.data.non_field_errors) {
                        response.data.non_field_errors.forEach(errorMsg => {
                            this.$toast.error(errorMsg, { duration: 3000 });
                        })
                    } else if (response.data.detail && typeof response.data.detail == 'string') {
                        this.$toast.error(response.data.detail, { duration: 3000 });
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss">
.adicionar-area-atuacao {
    width: 98%;
    max-width: 500px;
   
    .m-inputs {
        max-height: 60%;
    }
    .m-buttons {
        max-height: 25%;
    }
    .waiting-add {
        margin-right: 10px;
        width: 25px;
        height: auto;
    }
    
}
</style>
